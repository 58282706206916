import { ContactlessOutlined } from "@mui/icons-material";
import { Box, Container, Typography } from "@mui/material";

const Products = () => {
  return (
    <Container>
      <Box>
        <Typography
          variant="h5"
          paddingTop={2}
          color={"text.primary"}
          gutterBottom
        >
          Machine health monitoring solution
        </Typography>
      </Box>
      <Container>
        <Typography variant="body1" color={"text.secondary"} gutterBottom>
          The services include cloud connect health monitoring systems for
          different industrial machineries such as motors, pumps etc. using
          machine learning techniques and artificial intelligence.
        </Typography>
      </Container>
      <Box>
        <Typography
          variant="h5"
          paddingTop={2}
          color={"text.primary"}
          gutterBottom
        >
          Smart build monitoring solution
        </Typography>
      </Box>
      <Container>
        <Typography variant="body1" color={"text.secondary"} gutterBottom>
          Another service from the company is to provide cloud connected and
          smart building monitoring systems. As of now, the products are under
          development.
        </Typography>
      </Container>
    </Container>
  );
};

export default Products;
