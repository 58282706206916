import { API } from "aws-amplify";
import { createContact } from "../graphql/mutations";
import { Button, Grid, TextField, Typography } from "@mui/material";
import { Container } from "@mui/system";
import { useState } from "react";

const Contact = () => {
  const [firstName, setFristName] = useState("");
  const [lastName, setLastName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");

  const resetHandler = () => {
    setFristName("");
    setLastName("");
    setCompanyName("");
    setEmail("");
    setPhone("");
    setMessage("");
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    console.log("First Name: ", firstName);
    console.log("Last Name: ", lastName);
    console.log(companyName);
    console.log(email);
    console.log(phone);
    console.log(message);
    await API.graphql({
      query: createContact,
      variables: {
        input: {
          firstName,
          lastName,
          companyName,
          phone,
          email,
          message,
        },
      },
    });
    resetHandler();
  };
  return (
    <Container>
      <Grid container padding={1}>
        <Typography variant="h5" color={"text.primary"} gutterBottom>
          Contact Us
        </Typography>
      </Grid>
      <Grid container specing={1} padding={3}>
        <form onSubmit={handleFormSubmit}>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <TextField
                label="First Name"
                value={firstName}
                onInput={(e) => setFristName(e.target.value)}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Last Name"
                value={lastName}
                onInput={(e) => setLastName(e.target.value)}
                fullWidth
                required
              />
            </Grid>
            <Grid xs={12} item>
              <TextField
                label="Company Name"
                value={companyName}
                type={"text"}
                onInput={(e) => setCompanyName(e.target.value)}
                fullWidth
                required
              />
            </Grid>
            <Grid xs={12} item>
              <TextField
                type={"tel"}
                label="Phone Number"
                value={phone}
                onInput={(e) => setPhone(e.target.value)}
                fullWidth
                required
              />
            </Grid>
            <Grid xs={12} item>
              <TextField
                type={"email"}
                label="Email"
                value={email}
                onInput={(e) => setEmail(e.target.value)}
                fullWidth
                required
              />
            </Grid>
            <Grid xs={12} item>
              <TextField
                label="Message"
                value={message}
                onInput={(e) => setMessage(e.target.value)}
                fullWidth
                multiline
                rows={5}
                autoComplete="none"
                required
              />
            </Grid>
            <Grid xs={12} item>
              <Button variant="contained" sx={{backgroundColor:"primary.light",color: "primary.contrastText"}} type="submit">
                Send Message
              </Button>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Container>
  );
};

export default Contact;
