import { ContactlessOutlined } from "@mui/icons-material";
import { Box, Container, Typography } from "@mui/material";

const About = () => {
  return (
    <Container>
      <Box>
        <Typography
          variant="h5"
          paddingTop={2}
          color={"text.primary"}
          gutterBottom
        >
          Who we are?
        </Typography>
      </Box>
      <Container>
        <Typography variant="body1" color={"text.secondary"} gutterBottom>
          Barry Slice Inc. is a IoT based software company. The main focus of
          the company is to provide IoT based services to various industries
          with the help of cloud computing technologies.
        </Typography>
      </Container>
    </Container>
  );
};

export default About;
