import { Box, Container, Typography } from "@mui/material";

const Footer = () => {
  return (
    <Box
      px={{ xs: 3, sm: 3 }}
      py={{ xs: 3, sm: 3 }}
      display={"flex"}
      flexDirection={"column"}
      mt={"auto"}
      bgcolor={"primary.main"}
      // color={"primary"}
    >
      <Container>
        <Box textAlign={"center"} pt={{ xs: 3, sm: 3 }} pb={{ xs: 3, sm: 0 }}>
          <Typography variant="body1" color={"text.secondary"}>
            &copy; {new Date().getFullYear()}, Berry Slice Inc.
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
