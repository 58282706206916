import { useState, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import Logo from "./Logo";
import {
  AppBar,
  Toolbar,
  Button,
  Stack,
  Typography,
  Container,
} from "@mui/material";
import "./Navbar.module.css";

const menuItems = [
  {
    title: "Home",
    url: "/",
  },
  {
    title: "About",
    url: "/about",
  },
  {
    title: "Products",
    url: "/products",
  },
  {
    title: "Contact",
    url: "/contact",
  },
];

const Navbar = () => {
  const [openLinks, setOpenLinks] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const location = useLocation();

  useEffect(() => {
    menuItems.map((menuItem, index) => {
      if (location.pathname === menuItem.url) {
        setActiveIndex(index);
      }
    });
  }, []);

  return (
    <AppBar position="static">
      <Toolbar>
        <Logo onLogoClickHandler={setActiveIndex} />
        <Container>
          <Stack
            direction={"row"}
            justifyContent={"flex-end"}
            alignItems={"baseline"}
            spacing={2}
          >
            {menuItems.map((menuItem, index) => {
              return (
                <Button
                  key={menuItem.title}
                  LinkComponent={NavLink}
                  to={menuItem.url}
                  color={activeIndex === index ? "secondary" : "inherit"}
                  variant={"text"}
                  onClick={() => {
                    setActiveIndex(index);
                    // console.log(menuItems.length)
                  }}
                >
                  {menuItem.title}
                </Button>
              );
            })}
          </Stack>
        </Container>
      </Toolbar>
    </AppBar>
  );
};
export default Navbar;
