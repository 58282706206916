import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About";
import Products from "./pages/Products";
import Contact from "./pages/Contact";
import Navbar from "./components/Navbar";
import { Box, ThemeProvider } from "@mui/system";
import theme from "./theme/CustomTheme";
import "./App.css";
import { useEffect } from "react";
import { Button } from "@mui/material";
import Footer from "./components/Footer";

function App() {
  useEffect(() => {
    document.title = "Berry Slice Inc.";
  }, []);
  return (
    <ThemeProvider theme={theme}>
      <Box
        display={"flex"}
        flexDirection={"column"}
        minHeight={"100vh"}
        flex={1}
      >
        <Navbar />
        <Box>
          <Routes>
            <Route path="/" exact element={<Home />} />
            <Route path="/about" exact element={<About />} />
            <Route path="/products" exact element={<Products />} />
            <Route path="/contact" exact element={<Contact />} />
          </Routes>
        </Box>
        <Footer />
      </Box>
    </ThemeProvider>
  );
}
export default App;

// import logo from "./logo.svg";
// import "./App.css";

// function App() {
//   return (
//     <div>
//       <div className="App-top">Bismillahir Rahmanir Raheem</div>

//       <img src={logo} className="App-logo" alt="logo" />
//       {/* <header className="App-header">
//         <img src={logo} className="App-logo" alt="logo" />
//         <p>
//           Edit <code>src/App.js</code> and save to reload.
//         </p>
//         <a
//           className="App-link"
//           href="https://reactjs.org"
//           target="_blank"
//           rel="noopener noreferrer":w

//         >
//           Learn React
//         </a>
//       </header> */}
//     </div>
//   );
// }

// export default App;
