import image from "../assets/bs-banner2.png";
import style from "./FeatureImage.module.css";

const FeatureImage = () => {
  return (
    <>
      <img src={image} className={style.image} alt="" />
    </>
  );
};
export default FeatureImage;
