import { Link } from "react-router-dom";
import logoImage from "../bs-logo.svg";
import styles from "./Logo.module.css";

function Logo(props) {
  return (
    <Link to={"/"} className={styles.logo}>
      <img
        src={logoImage}
        alt="logo"
        onClick={() => {
          props.onLogoClickHandler(0);
        }}
      />
    </Link>
  );
}
export default Logo;
