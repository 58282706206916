import { createTheme } from "@mui/material";
import { green, red, teal } from "@mui/material/colors";

export const Colors = {
  base: {
    superlight: "#E2F6FB",
    light: "#CBECF4",
    regular: "#B2DFEA",
    dark: "#99D1DE",
    superdark: "#7FC0CF",
  },
  complementary: {
    superlight: "#FFF2E6",
    light: "#FFE9D3",
    regular: "#FFDFC0",
    dark: "#FFD6AD",
    superdark: "#FFCC9A",
  },
};

const theme = createTheme({
  palette: {
    primary: {
      main: Colors.base.dark, //  "#b2dfea",// "#00a5cf",
      contrastText: "#4d6970",// Colors.base.superlight, // Colors.base.superdark, // "#daf0f4"
    },
    secondary: {
      main: "#141b1d" , //  "#41545b" ,// "#324347", // Colors.secondary,
      contrastText: Colors.base.superlight,
    },
    text: {
      primary: "#46b1c4",
      secondary: "#4d6970",
    },
  },
});
export default theme;
